<template>
  <div
      :class="small ? 'm-1' : 'm-3'"
      class="flex justify-center">
    <div
        class="flex items-center gap-1 border border-solid border-1 border-theme-bad rounded-md bg-white"
        :class="small ? 'py-1 px-2' : 'py-2 px-2.5'"
    >
      <svg
          class="stroke-theme-bad fill-theme-bad"
          :width="small ? 30 : 50"
          :height="small ? 30 : 50"
          viewBox="0 0 256 256">
        <path
            d="M124,144V104a4,4,0,0,1,8,0v40a4,4,0,0,1-8,0Zm109.30273,65.98291a19.79613,19.79613,0,0,1-17.32617,10.00928H40.02344a19.9998,19.9998,0,0,1-17.30957-30.019L110.69043,37.981a20.00012,20.00012,0,0,1,34.61914,0l87.97656,151.99218A19.798,19.798,0,0,1,233.30273,209.98291Zm-6.94043-16.00244L138.38574,41.98828a12.00037,12.00037,0,0,0-20.77148,0L29.6377,193.98047a12.00033,12.00033,0,0,0,10.38574,18.01172H215.97656a12.00033,12.00033,0,0,0,10.38574-18.01172ZM128,172a8,8,0,1,0,8,8A7.99977,7.99977,0,0,0,128,172Z"
            stroke-width="4"
        />
      </svg>
      <p
          :class="small ? 'text-lg' : 'text-3xl'"
          class="font-semibold text-theme-bad">{{ errorMessage }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
const errorMessage = ref<string>();
const props = defineProps<{
  message?: string,
  small?: boolean
}>()
const i18n = useI18n();

if (props.message) {
  const translation = i18n.t("error." + props.message)
  if (translation != "error." + props.message) {
    errorMessage.value = translation
  } else {
    errorMessage.value = props.message
  }
} else {
  errorMessage.value = i18n.t("error.generic")
}
</script>